import React from 'react';
import PropTypes from 'prop-types';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { Tooltip } from 'react-tooltip';

const PartialTable = ({
  data,
  columns,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage,
  tableRef,
  loading = false,
  handleClickRow = () => {},
  textAlign = "text-center",
  option = {},
  style,
  renderExpandedRow,
  expandedRowIndex,
  setExpandedRowIndex,
  setExpandedRowData,
  expandedRowData
}) => {
  let headerClassName = `bg-200 text-900 align-middle ${textAlign}`; //text-nowrap
  if(option.isStickyHeader == true) {
    headerClassName = headerClassName + " sticky-header "
  }
 
  return (
    <div>
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={paging?.pageSize}
        selectionColumnWidth="20%"
      >
        <AdvanceTable
          table
          style={style}
          headerClassName={headerClassName}
          rowClassName={`align-middle white-space-nowrap ${textAlign}`} 
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0',
            responsive: true
          }}
          tableRef={tableRef}
          loading={loading}
          handleClickRow={handleClickRow}
          renderExpandedRow={renderExpandedRow}
          expandedRowIndex={expandedRowIndex}
          setExpandedRowIndex={setExpandedRowIndex}
          setExpandedRowData={setExpandedRowData}
          expandedRowData={expandedRowData}
        />
        <Tooltip id="my-tooltip" />
        <div className="mt-3 mb-3">
          <AdvanceTablePagination
            canPreviousPage={paging?.pageNum > 1}
            canNextPage={paging?.pageNum < Math.ceil(total / paging?.pageSize)}
            previousPage={() => {
              previousPage()
              handleClickRow(
                null,
                setExpandedRowIndex,
                expandedRowIndex,
                setExpandedRowData
              )
            }}
            nextPage={() => {
              nextPage()
              handleClickRow(
                null,
                setExpandedRowIndex,
                expandedRowIndex,
                setExpandedRowData
              )
            }}
            pageCount={Math.ceil(total / paging?.pageSize)}
            pageIndex={paging?.pageNum - 1}
            gotoPage={(index) => {
              gotoPage(index)
              handleClickRow(
                null,
                setExpandedRowIndex,
                expandedRowIndex,
                setExpandedRowData
              )
            }}
          />
        </div>
      </AdvanceTableWrapper>
    </div>
  );
};

PartialTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  paging: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func,
  view: PropTypes.string,
  tableRef: PropTypes.object,
  loading: PropTypes.bool,
  textAlign: PropTypes.string,
  handleClickRow:  PropTypes.func,
  option: PropTypes.object,
  style: PropTypes.object,
  renderExpandedRow: PropTypes.func,
  expandedRowIndex: PropTypes.number,
  setExpandedRowIndex: PropTypes.func,
  setExpandedRowData: PropTypes.func,
  expandedRowData: PropTypes.object,
};

export default PartialTable;
