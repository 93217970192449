import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import { departments } from 'data/TempData';
import UserDropdownV2 from 'components/Dropdowns/CustomerServices/UserDropdownV2';
import React from 'react';
import PropTypes from 'prop-types';
import DepartmentDropdown from 'components/Dropdowns/CustomerServices/DepartmentDropdown';
import PriorityDropdown from 'components/Dropdowns/CustomerServices/PriorityDropdown';
import ProgressDropdown from 'components/Dropdowns/CustomerServices/ProgressDropdown';
import InputDatePicker from '../create-action-plan/InputDatePicker';
const ActionPlanFilter = ({ formData, handleChange, handleSubmit }) => {
  return (
    <Card>
      <Card.Header as="h5">Fitler</Card.Header>
      <Card.Body className="bg-light">
        <Row>
          <Col md="12">
            <Form.Group controlId="eventTitle">
              <Form.Label>Keyword</Form.Label>
              <Form.Control
                type="text"
                name="keyword"
                placeholder="Keyword"
                onChange={e => {
                  handleChange('keyword', e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <InputDatePicker
              column="targetDate"
              displayName="Target Date"
              selected={formData?.targetDate}
              handleChange={handleChange}
            />
          </Col>
          <Col md="6">
            <ProgressDropdown
              label="Progresses"
              fieldName="progresses"
              isMulti
              placeholder="Progresses ..."
              handleChange={handleChange}
              value={formData?.progresses}
            />
          </Col>
          <Col md="12">
            <UserDropdownV2
              label="Responsible persons"
              fieldName="assignees"
              handleChange={handleChange}
              placeholder="Responsible persons..."
              value={formData?.assignees}
            />
          </Col>
          <Col md="12">
            <UserDropdownV2
              label="Request participants"
              fieldName="requestParticipants"
              handleChange={handleChange}
              placeholder="Request participants..."
              value={formData?.requestParticipants}
            />
          </Col>
          <Col md="6">
            <UserDropdownV2
              label="Reporters"
              fieldName="reporters"
              handleChange={handleChange}
              placeholder="Reporters..."
              value={formData?.reporters}
              isMulti
            />
          </Col>
          <Col md="6">
            <DepartmentDropdown
              label="Departments"
              fieldName="departments"
              closeMenuOnSelect={false}
              isMulti
              options={departments}
              placeholder="Department"
              handleChange={handleChange}
              value={formData?.departments}
            />
          </Col>
          <Col md="6"></Col>

          <Col md="12">
            <div className="border-dashed-bottom"></div>
          </Col>
          <Col md="6">
            <Form.Group controlId="relativeJobNumbers">
              <Form.Label>Relative Job Numbers</Form.Label>
              <Form.Control
                type="text"
                placeholder="Relative Job Numbers"
                name="relativeJobNumbers"
                value={formData?.relativeJobNumbers}
                onChange={e => {
                  handleChange('relativeJobNumbers', e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <PriorityDropdown
              label="Priorities"
              fieldName="priorities"
              closeMenuOnSelect={false}
              isMulti
              placeholder="Priorities ..."
              handleChange={handleChange}
              value={formData?.priority}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <hr />
        <div className="text-center mt-2">
          <Button color="primary" onClick={handleSubmit}>
            Search
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};
ActionPlanFilter.propTypes = {
  formData: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func
};

export default ActionPlanFilter;
