import { Card, Form, Row, Col } from 'react-bootstrap';
import { departments } from '../../../../../data/TempData';
import UserDropdownV2 from 'components/Dropdowns/CustomerServices/UserDropdownV2';
import React from 'react';
import PropTypes from 'prop-types';
import DepartmentDropdown from 'components/Dropdowns/CustomerServices/DepartmentDropdown';
import PriorityDropdown from 'components/Dropdowns/CustomerServices/PriorityDropdown';
import ProgressDropdown from 'components/Dropdowns/CustomerServices/ProgressDropdown';
import InputDatePicker from './InputDatePicker';
import { getItemFromStore } from 'helpers/utils';

const ActionPlanRightInfo = ({ formData, handleChange }) => {
  const currentUser = getItemFromStore('user', {}, sessionStorage);
  return (
    <Card>
      <Card.Header as="h5">Other Info</Card.Header>
      <Card.Body className="bg-light">
        <Row>
          <Col md="6">
            <InputDatePicker
              column="targetDate"
              displayName="Target Date"
              selected={formData?.targetDate}
              handleChange={handleChange}
            />
          </Col>
          <Col md="6">
            <ProgressDropdown
              label="Progress"
              fieldName="progress"
              isMulti={false}
              placeholder="Progress ..."
              handleChange={handleChange}
              value={formData?.progress ? formData?.progress : 1}
            />
          </Col>
          <Col md="12">
            <UserDropdownV2
              label="Responsible persons"
              fieldName="assignees"
              handleChange={handleChange}
              placeholder="Responsible persons..."
              value={formData?.assignees}
            />
          </Col>
          <Col md="12">
            <UserDropdownV2
              label="Request participants"
              fieldName="requestParticipants"
              handleChange={handleChange}
              placeholder="Request participants..."
              value={formData?.requestParticipants}
            />
          </Col>
          <Col md="6">
            <UserDropdownV2
              label="Reporter"
              fieldName="reporter"
              handleChange={handleChange}
              placeholder="Reporter..."
              value={
                formData?.reporter ? formData?.reporter : currentUser.userId
              }
              isMulti={false}
            />
          </Col>
          <Col md="6">
            <DepartmentDropdown
              label="Departments"
              fieldName="departments"
              closeMenuOnSelect={false}
              isMulti
              options={departments}
              placeholder="Department"
              handleChange={handleChange}
              value={formData?.departments}
            />
          </Col>
          <Col md="6"></Col>

          <Col md="12">
            <div className="border-dashed-bottom"></div>
          </Col>
          <Col md="6">
            <Form.Group controlId="relativeJobNumbers">
              <Form.Label>Relative Job Numbers</Form.Label>
              <Form.Control
                type="text"
                placeholder="Relative Job Numbers"
                name="relativeJobNumbers"
                value={formData?.relativeJobNumbers}
                onChange={e => {
                  handleChange('relativeJobNumbers', e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <PriorityDropdown
              label="Priority"
              fieldName="priority"
              closeMenuOnSelect={false}
              isMulti={false}
              placeholder="Priority ..."
              handleChange={handleChange}
              value={formData?.priority}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
ActionPlanRightInfo.propTypes = {
  formData: PropTypes.object,
  handleChange: PropTypes.func
};

export default ActionPlanRightInfo;
