import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { applyUserPermission } from 'slices/userPermissionSlice';
import { Button, Card, Col, Row, Spinner, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { toast } from 'react-toastify';
import {
  getById,
  post,
  patch
} from 'services/permissions/userPermissionServices';
import BasicTemplate from 'components/Toast/BasicTemplate';
import UserDropdownV2 from 'components/Dropdowns/CustomerServices/UserDropdownV2';
import PermissionSetupDropDown from 'components/Dropdowns/PermissionSetupDropDown';
export default function UserPermissionManager() {

  const [params] = useSearchParams();
  const [isWaitingSave, setIsWaitingSave] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector(state => state.userPermission.selectedUserPermission);
  const [validated, setValidated] = useState(false);
  useEffect(() => {
    fetchData();
    return () => {
      dispatch(applyUserPermission(null));
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    setIsWaitingSave(true);

    let result = null;
    if (data.id <= 0 || data.id == undefined) {
      if (data.evaUserId == undefined || data.evaUserId == 0 || data.evaUserId == null) {
        toast.error(<BasicTemplate success={false} text={"Please select user"} />);
        setIsWaitingSave(false);
        return;
      }
      if (data.permissionSetupId == undefined || data.permissionSetupId == 0 || data.permissionSetupId == null) {
        toast.error(<BasicTemplate success={false} text={"Please select Permisssion"} />);
        setIsWaitingSave(false);
        return;
      }
      result = await post(data);
    } else {
      result = await patch(data, data.id);
    }

    const message =
      result.message ??
      'There was a problem saving the data. Please check your entires and try again.';
    result.success
      ? toast.success(
        <BasicTemplate
          success={result.success}
          text="User Permission successfully saved!"
        />
      )
      : toast.error(<BasicTemplate success={result.success} text={message} />);
    window.scrollTo(0, 0);
    setIsWaitingSave(false);
    return;
  };

  const fetchData = () => {
    if (params.has('id')) {
      const id = params.get('id');
      getById(id).then(res => {
        console.log(res?.data);
        if (res?.data && res?.data.id > 0) {
          dispatch(
            applyUserPermission({
              ...res?.data
            })
          );
          return;
        }
      });
      return;
    }

    dispatch(applyUserPermission({}));
    return;
  };
  const checkChange = (e, key) => {
    const value = e.target.checked;
    switch (key) {
      case 'view':
        dispatch(
          applyUserPermission({
            ...data,
            viewPermission: value
          })
        );
        break;
      case 'update':
        dispatch(
          applyUserPermission({
            ...data,
            updatePermission: value
          })
        );
        break;
      case 'delete':
        dispatch(
          applyUserPermission({
            ...data,
            deletePermission: value
          })
        );
        break;
      case 'create':
        dispatch(
          applyUserPermission({
            ...data,
            createPermission: value
          })
        );
        break;
      default:
        break;
    }
    return;
  };

  const singleSelectChange = (key, option) => {
    console.log(key, option)
    switch (key) {
      case 'evaUser':
        dispatch(
          applyUserPermission({
            ...data,
            evaUserId: option.value
          })
        );
        break;
      case 'permissionSetup':
        dispatch(
          applyUserPermission({
            ...data,
            permissionSetupId: option.value
          })
        );
        break;
    }
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Col xs={12}>
        <PageHeader
          title={data && data.id > 0 ? 'Edit ' + data.code : 'Create User Permission'}
          description={`<p>Use this page to manage your User Permission. Assign actions below the description.</p>`}
          className="mb-3"
        ></PageHeader>
        <Card>
          <Card.Body>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="code">
                <UserDropdownV2
                  label="Eva User"
                  fieldName="evaUser"
                  handleChange={singleSelectChange}
                  placeholder="Eva User..."
                  value={data?.evaUserId}
                  isMulti={false}
                />

                {/* <EvaUserDropDown
                  selectedOptions={data?.evaUserId}
                  handleChange={opts => singleSelectChange(opts, 'evaUser')}
                  isMulti={false}
                /> */}
                <Form.Control.Feedback type="invalid">
                  Please provide a Code.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="name">
                <Form.Label>Name</Form.Label>

                <PermissionSetupDropDown
                  selectedOptions={data?.permissionSetupId}
                  handleChange={opts => singleSelectChange('permissionSetup', opts)}
                  isMulti={false}
                />

                <Form.Control.Feedback type="invalid">
                  Please provide a Name.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="3" controlId="name">
                <Form.Check
                  type='checkbox'
                  id='viewCheck'
                  label='View'
                  onChange={opts => checkChange(opts, 'view')}
                  checked={data?.viewPermission}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="name">
                <Form.Check
                  type='checkbox'
                  id='createCheck'
                  label='Create'
                  onChange={opts => checkChange(opts, 'create')}
                  checked={data?.createPermission}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="name">
                <Form.Check
                  type='checkbox'
                  id='updateCheck'
                  onChange={opts => checkChange(opts, 'update')}
                  label='Update'
                  checked={data?.updatePermission}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="name">
                <Form.Check
                  type='checkbox'
                  onChange={opts => checkChange(opts, 'delete')}
                  id='deleteCheck'
                  label='Delete'
                  checked={data?.deletePermission}
                />
              </Form.Group>
            </Row>
            <Row>
              <Col xs={6}>
                <Button
                  variant="primary"
                  disabled={isWaitingSave}
                  type="submit"
                // onClick={() => saveData()}
                >
                  {!isWaitingSave ? (
                    'Save'
                  ) : (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span> ...Loading</span>
                    </>
                  )}
                </Button>
              </Col>
              <Col xs={6}>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Form >
  );
}
