export const persons = [
  { value: 1, label: 'Duc NT' },
  { value: 2, label: 'TrungT' },
  { value: 3, label: 'Thien' },
  { value: 4, label: 'AN' }
];
export const departments = [
  { value: 1, label: 'PRODUCTION' },
  { value: 2, label: 'QA' },
  { value: 3, label: 'BA' },
  { value: 4, label: 'Driver' }
];
export const progresses = [
  { value: 1, label: 'To Do' },
  { value: 2, label: 'Inprogress' },
  { value: 3, label: 'Completed' }
];

export const priorites = [
  {
    value: 1,
    label: 'P1 - Critical',
    imageUrl: 'https://emplas.atlassian.net/images/icons/priorities/highest.svg'
  },
  {
    value: 2,
    label: 'P2 - Hight',
    imageUrl: 'https://emplas.atlassian.net/images/icons/priorities/high.svg'
  },
  {
    value: 3,
    label: 'P3 - Normal',
    imageUrl: 'https://emplas.atlassian.net/images/icons/priorities/medium.svg'
  },
  {
    value: 4,
    label: 'P4 - Medium',
    imageUrl: 'https://emplas.atlassian.net/images/icons/priorities/low.svg'
  },
  {
    value: 5,
    label: 'P5 - Low',
    imageUrl: 'https://emplas.atlassian.net/images/icons/priorities/lowest.svg'
  }
];
