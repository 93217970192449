import { useEffect, React } from 'react';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import 'quill/dist/quill.snow.css';
import { UploadFile } from 'helpers/fileHelper';
import Quill from 'quill';

import PropTypes from 'prop-types';

let BlockEmbed = Quill.import('blots/block/embed');
class ImageBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    if (typeof value === 'string') {
      node.setAttribute('src', value);
    } else {
      node.setAttribute('src', value.url);
      node.setAttribute('alt', value.alt);
      node.setAttribute('width', value.width);
      node.setAttribute('height', value.height);
      node.setAttribute('style', value.style);
    }
    return node;
  }

  static value(node) {
    return {
      alt: node.getAttribute('alt'),
      url: node.getAttribute('src'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      style: node.getAttribute('style')
    };
  }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';
Quill.register(ImageBlot);

const Editor = ({
  initialText,
  imageFolder,
  handleChangeOut,
  minHeight = 300,
  placeholder = 'Type /canned to create or insert predefined responses.'
}) => {
  const { quill, quillRef, Quill } = useQuill({
    modules: { blotFormatter: {} },
    placeholder: placeholder
  });

  if (Quill && !quill) {
    Quill.register('modules/blotFormatter', BlotFormatter);
  }

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(initialText);
      quill.getModule('toolbar').addHandler('image', selectLocalImage);
      quill.on('text-change', () => {
        handleChangeOut(quillRef.current.firstChild.innerHTML);
      });
    }
  }, [quill, Quill, initialText]);

  const insertToEditor = url => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', url);
  };

  const saveToServer = async file => {
    const urlUpload = await UploadFile(imageFolder, file);
    console.log(urlUpload);
    insertToEditor(urlUpload);
  };

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  return (
    <div className="editor-parent" style={{ width: '100%' }}>
      <div
        style={{
          minHeight: minHeight,
          marginBottom: '10px',
          backgroundColor: 'white'
        }}
        ref={quillRef}
      />
    </div>
  );
};

Editor.propTypes = {
  imageFolder: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleChangeOut: PropTypes.func,
  placeholder: PropTypes.string,
  initialText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minHeight: PropTypes.object
};

export default Editor;
