import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Table, Spinner } from 'react-bootstrap';

const AdvanceTable = ({
  getTableProps,
  headers,
  headerGroups,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  tableRef,
  style,
  handleClickRow,
  renderExpandedRow,
  loading = false,
  expandedRowIndex,
  setExpandedRowIndex,
  setExpandedRowData,
  expandedRowData
}) => {
  
  return (
    <div>
      <SimpleBarReact>
        <Table {...getTableProps(tableProps)} ref={tableRef} style={style}>
          <thead className={headerClassName}>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    style={{ position: 'fixed', top: 0 }}
                    key={i}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps(column.headerProps)
                    )}
                  >
                    {column.render('Header')}
                    {column.canSort ? (
                      column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className="sort desc" />
                        ) : (
                          <span className="sort asc" />
                        )
                      ) : (
                        <span className="sort" />
                      )
                    ) : (
                      ''
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {loading && [
              <tr key="table-row-loading" className="text-center">
                <td colSpan={headers.length}>
                  <Spinner
                    variant="primary"
                    animation="border"
                    role="status"
                  ></Spinner>
                </td>
              </tr>
            ]}
            {page &&
              page.length > 0 &&
              !loading &&
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <React.Fragment key={i}>
                    <tr
                      key={i}
                      className={rowClassName}
                      {...row.getRowProps({
                        onClick: () =>
                          handleClickRow(
                            row,
                            setExpandedRowIndex,
                            expandedRowIndex,
                            setExpandedRowData
                          )
                        // {
                        //   handleClickRow(row);
                        //   setExpandedRowIndex(
                        //     expandedRowIndex === row.index ? null : row.index
                        //   );
                        // }
                      })}
                    >
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            key={index}
                            {...cell.getCellProps(cell.column.cellProps)}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                    {expandedRowIndex === row.index && renderExpandedRow && (
                      <tr>
                        <td colSpan={row.cells.length}>
                          {/* {renderExpandedRow(row.original)} */}
                          {expandedRowData[row.id] ? (
                            renderExpandedRow(expandedRowData[row.id]) // Hiển thị dữ liệu mở rộng nếu có
                          ) : (
                            <div>Loading...</div> // Hiển thị khi đang chờ API trả về dữ liệu
                          )}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            {page &&
              !page.length &&
              !loading && [
                <tr key="table-row-empty" className="text-center">
                  <td colSpan={headers.length}>No Results</td>
                </tr>
              ]}
          </tbody>
        </Table>
      </SimpleBarReact>
    </div>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  loading: PropTypes.bool,
  headerGroups: PropTypes.array,
  style: PropTypes.object,
  handleClickRow: PropTypes.func,
  tableRef: PropTypes.object,
  renderExpandedRow: PropTypes.any,
  expandedRowIndex: PropTypes.number,
  setExpandedRowIndex: PropTypes.func,
  setExpandedRowData: PropTypes.func,
  expandedRowData: PropTypes.object,
};

export default AdvanceTable;
