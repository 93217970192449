import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ActionPlanTableHeader from './ActionPlanTableHeader';
import ActionPlanTable from './ActionPlanTable';
import { joinDataMultiDropdown } from 'helpers/dropdownFuncHelper';
import { BaseTableRQ } from 'components/common/models/BaseClass';
import { searchActionPlan } from 'services/customer-service/scActionPlanService';
import ActionPlanFilter from './ActionPlanFilter';
import PropTypes from 'prop-types';

const RunningActionPlans = ({ meetingId }) => {
  const defaultRQ = { ...new BaseTableRQ(1, 5), meetingId: meetingId };
  const [actionPlans, setActionPlans] = useState([]);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({ ...defaultRQ, meetingId: meetingId });
  const [isFilter, setIsFilter] = useState(false);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    setIsFilter(false);
  }, []);

  const gotoPage = index => {
    index += 1;
    if (index <= 0) return;
    paging.pageNum = index;
    loadActionPlans(paging);
  };

  const toggelFilter = () => {
    setIsFilter(!isFilter);
  };

  const nextPage = () => {
    const page = paging.pageNum + 1;
    if (page > Math.ceil(total / paging.pageSize)) return;
    paging.pageNum = page;
    loadActionPlans(paging);
  };

  const previousPage = () => {
    const page = paging.pageNum - 1;
    if (page <= 0) return;
    paging.pageNum = page;
    loadActionPlans(paging);
  };

  const handleChange = (field, value) => {
    setFilter({
      ...filter,
      [field]: value
    });
  };

  const handleSubmit = () => {
    let rq = mapRQData(filter);
    loadActionPlans({ ...defaultRQ, ...rq });
  };

  const mapRQData = data => {
    if (data) {
      let mapRq = { ...data };
      if (data?.targetDate) {
        mapRq.targetDate = data?.targetDate?.toISOString();
      }

      mapRq.progresses = joinDataMultiDropdown(data?.progresses);
      mapRq.reporters = joinDataMultiDropdown(data?.reporters);
      mapRq.priorities = joinDataMultiDropdown(data?.priorities);
      mapRq.assignees = joinDataMultiDropdown(data?.assignees);
      mapRq.requestParticipants = joinDataMultiDropdown(
        data?.requestParticipants
      );
      mapRq.departments = joinDataMultiDropdown(data?.departments);
      return mapRq;
    }
    return null;
  };

  useEffect(() => {
    loadActionPlans(defaultRQ);
  }, []);

  const loadActionPlans = pagingRq => {
    searchActionPlan(pagingRq)
      .then(res => {
        if (res) {
          setActionPlans(res.items);
          setTotal(res.total);
        }
      })
      .catch(err => {
        console.log(err);
      });
    setPaging(pagingRq);
  };

  return (
    <Card>
      <Row style={{ padding: '10px' }}>
        <ActionPlanTableHeader
          toggelFilter={() => toggelFilter()}
          meetingId={meetingId}
          style={{ padding: '10px' }}
        />
        <Col lg={isFilter ? 9 : 12}>
          <ActionPlanTable
            data={actionPlans}
            paging={paging}
            total={total}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
          />
        </Col>
        {isFilter && (
          <Col lg={3}>
            <ActionPlanFilter
              handleChange={handleChange}
              formData={filter}
              handleSubmit={handleSubmit}
            />
          </Col>
        )}
      </Row>
    </Card>
  );
};

RunningActionPlans.propTypes = {
  meetingId: PropTypes.string,
};

export default RunningActionPlans;
