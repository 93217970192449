import React from 'react';
import IconButton from 'components/common/IconButton';
import FalconCardHeader from 'components/common/FalconCardHeader';
import PropTypes from 'prop-types';

const SkillActivityTableHeader = ({ toggelModal }) => {
  return (
    <div>
      <FalconCardHeader
        title="Skill Activity"
        light
        titleTag="h6"
        endEl={
          <div id="orders-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              onClick={toggelModal}
            >
              <span className="d-none d-sm-inline-block ms-1">New</span>
            </IconButton>
            {/* <IconButton
              variant="falcon-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
              className="mx-2"
              onClick={toggelFilter}
            >
              <span className="d-none d-sm-inline-block ms-1">Filter</span>
            </IconButton> */}
          </div>
        }
      />
    </div>
  );
};

SkillActivityTableHeader.propTypes = {
  toggelFilter: PropTypes.func,
  toggelModal: PropTypes.func
};

export default SkillActivityTableHeader;
