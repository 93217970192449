import React from 'react';
import IconButton from 'components/common/IconButton';
import FalconCardHeader from 'components/common/FalconCardHeader';
import PropTypes from 'prop-types';

const TrainingRecordTableHeader = ({ toggelFilter }) => {

  return (
    <div>
      <FalconCardHeader
        title=""
        light
        titleTag="h6"
        endEl={
          <div
            id="orders-actions"
            className="d-flex align-items-center justify-content-between"
          >
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
              className="mx-2"
              onClick={toggelFilter}
            >
              <span className="d-none d-sm-inline-block ms-1">Filter</span>
            </IconButton>
          </div>
        }
      />
    </div>
  );
};

TrainingRecordTableHeader.propTypes = {
  toggelFilter: PropTypes.func
};

export default TrainingRecordTableHeader;
