import { useSelector } from 'react-redux';
import { getItemFromStore } from 'helpers/utils';
import { management, adminRoutes } from 'routes/routes';

const PermissionType = {
  View: 1,
  Create: 2,
  Update: 3,
  Delete: 4
};

const PermissionOperation = {
  And: 1,
  Or: 2
};

// This is perrmission Page Name in BE
const PermissionCode = {
  SlsAreas: 'SlsAreas',
  SlsBins: 'SlsBins',
  SlsLocations: 'SlsLocations',
  SlsSites: 'SlsSites',
  SLsStocks: 'SlsStocks',
  PMSetup: 'PMSetup',
  PMUserPm: 'PMUserPm',

  SmArea: 'SmArea',
  SmSection: 'SmSection',
  SmSkillActivity: 'SmSkillActivity',
  SmSkillCriteria: 'SmSkillCriteria',
  SmSkillLevel: 'SmSkillLevel',
  SmSkillMaxtrix: 'SmSkillMaxtrix',
  SmSubSkill: 'SmSubSkill',
  SmSubSkillTraining: 'SmSubSkillTraining',
  SmTrainingRecord: 'SmTrainingRecord'
};

const fullPermission = () => {
  const stateIsFullPermission = useSelector(
    state => state.user.isFullPermission
  );
  const sessionIsFullPermission = getItemFromStore(
    'user',
    {},
    sessionStorage
  )?.isFullPermission;
  const isFullPermission = stateIsFullPermission || sessionIsFullPermission;
  return isFullPermission;
};

const permissions = () => {
  const statePermissions = useSelector(state => state.user.permissions);
  const sessionPermissions = getItemFromStore(
    'user',
    {},
    sessionStorage
  )?.permissions;
  let permissions;
  if (statePermissions) {
    permissions = statePermissions;
  } else if (sessionPermissions) {
    permissions = sessionPermissions;
  }
  return permissions;
};

const hasPermission = route => {
  const isFullPermission = fullPermission();
  const permissionSetup = permissions();
  
  if (isFullPermission) return true;
  if (
    route == null ||
    route == undefined ||
    permissionSetup == null ||
    permissionSetup == undefined
  )
    return false;
  if (
    route.permission_codes !== undefined &&
    route.permission_codes !== null &&
    route.permission_codes?.length > 0
  ) {
    const existedPermissions = permissionSetup?.filter(i =>
      route.permission_codes.includes(i.pageName)
    );

    if (existedPermissions == undefined || existedPermissions.length == 0) {
      return false;
    }

    if (
      (route.to == undefined || route.to == null) &&
      existedPermissions.length > 0
    ) {
      return true;
    }

    const convertedPermissions = convertPermission(existedPermissions);
    const routePermissions = route.permissions;

    for (var i = 0; i < convertedPermissions.length; i++) {
      let item = convertedPermissions[i];
      let apiPermissions = item.permissions;
      if (route.permission_required_type == PermissionOperation.And) {
        if (routePermissions.every(i => apiPermissions.includes(i))) {
          return true;
        }
      } else {
        if (
          routePermissions != undefined &&
          routePermissions.some(i => apiPermissions.includes(i))
        ) {
          return true;
        }
      }
    }

    
    return false;
  }
  
  return true;
};

const hasPermissionWithPath = path => {
  let strPath = path;
  if (path.startsWith('/')) {
    strPath = path.substring(1);
  }
  var route = findRouteByPath(management.children, strPath);
  if (route) {
    return hasPermission(route);
  }
  route = findRouteByPath(adminRoutes.children, strPath);

  if (route) {
    return hasPermission(route);
  }

  return false;
};

const convertPermission = permissionsData => {
  const permissions = permissionsData.map(item => {
    const {
      pageName,
      viewPermission,
      createPermission,
      updatePermission,
      deletePermission
    } = item;
    return {
      pageName,
      permissions: [
        viewPermission ? PermissionType.View : null,
        createPermission ? PermissionType.Create : null,
        updatePermission ? PermissionType.Update : null,
        deletePermission ? PermissionType.Delete : null
      ].filter(Boolean)
    };
  });
  return permissions;
};

const findRouteByPath = (routes, path) => {
  for (const route of routes) {
    if (route.to === path) {
      return route;
    } else if (route.children) {
      const childMatch = findRouteByPath(route.children, path);
      if (childMatch) {
        return childMatch;
      }
    }
  }
  return null;
};

export {
  PermissionOperation,
  PermissionType,
  PermissionCode,
  hasPermission,
  hasPermissionWithPath
};
