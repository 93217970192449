import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const TraningRecordFilter = ({
  formData,
  handleChange,
  handleSubmit,
  handleClear
}) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(formData);
  }, [formData]);

  return (
    <Card>
      {/* <Card.Header as="h5">Fitler</Card.Header> */}
      <Card.Body className="bg-light">
        <Row>
          <Col md="12">
            <Form.Group controlId="eventTitle">
              <Form.Label>Keyword</Form.Label>
              <Form.Control
                type="text"
                name="keyword"
                placeholder="Keyword"
                onChange={e => handleChange('keyword', e.target.value)}
                value={data?.keyword ?? ""}
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <hr />
        <div className="text-center mt-2">
          <Button color="primary" onClick={handleSubmit}>
            Search
          </Button>
          <Button style={{backgroundColor:'grey', border: 'solid 1px grey', marginLeft: '5px'}}onClick={handleClear}>
            Clear
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};
TraningRecordFilter.propTypes = {
  formData: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleClear: PropTypes.func
};

export default TraningRecordFilter;
