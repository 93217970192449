import { URL } from '../../helpers/urlHelper';
import { baseSearch, basePost, basePatch, baseGet } from 'services/base-services/baseService';
import { getAllEvaUsers } from 'services/userServices';
const baseURL = `${URL}/sm-training-records`;
import axios from 'axios';
axios.defaults.withCredentials = true;

const search = async request => {
  return await baseSearch(baseURL, request);
};

const getNotCompletes = async request => {
  return await baseSearch(baseURL+"/get-not-completes", request);
};


const searchUsers = async request => {
  let res = await getAllEvaUsers();
  if(request.keyword) {
    res = res.filter(i => i.name.includes(request.keyword) || i.name.includes(request.keyword) || i.email.includes(request.keyword))
  }
  const start = (request.pageNum - 1) * request.pageSize;
  const end = start + request.pageSize; 
  const rs = {
    items: res.slice(start, end),
    total: res.length 
  };
  return rs;
  // return await baseSearch(`${URL}/sm-training-records/search-users`, request);
};

const post = async (request) => {
  return await basePost(baseURL, request);
};

const get = async (id) => {
  return await baseGet(baseURL, id);
};


const patch = async (id, request) => {
  return await basePatch(baseURL, request, id);
};

export { search, post, patch, get, searchUsers, getNotCompletes };
