import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import RunningTrainingRecord from './RunningTrainingRecord';
export default function CoreTrainingRecord() {
  return (
    <>
      <Row>
        <Col xs={12}>
          <PageHeader
            title={'Core Training Record'}
            description={``}
            className="mb-3"
            col={{ lg: 12 }}
          />
        </Col>
        <Col xs={12}>
           <RunningTrainingRecord/>
        </Col>
      </Row>
    </>
  );
}
