import React, { useState, useEffect } from "react";
import { Card, Row } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import UserPermissionTable from "components/partials/permissions/user-permission/UserPermissionTable";
import { search } from "services/permissions/userPermissionServices";
import { BaseTableRQ } from "components/pages/stock-location/models/BaseTable"
// import { useLocation } from 'react-router-dom';
// import { hasPermissionWithPath } from "helpers/permissionHelper";

export default function UserPermissionList() {
    const [userPermissions, setUserPermissions] = useState([]);
    const [total, setTotal] = useState(0);
    const [paging, setPaging] = useState(new BaseTableRQ(1, 100));

    // const location = useLocation();
    // const hasPermission = hasPermissionWithPath(location.pathname)

    useEffect(() => {
        loadUserPermissions(paging);
    }, [])

    const loadUserPermissions = (pagingRq) => {
        search(pagingRq).then(res => {
            if (res) {
                setUserPermissions(res.items);
                setTotal(res.total);
            }
        })
            .catch(err => {
                console.log(err);
            });

        setPaging(pagingRq);
    }

    const gotoPage = (index) => {
        index += 1;
        if (index <= 0) return;
        paging.pageNum = index;
        loadUserPermissions(paging);
    }

    const nextPage = () => {
        const page = paging.pageNum + 1;
        if (page > Math.ceil(total / paging.pageSize)) return;
        paging.pageNum = page;
        loadUserPermissions(paging);
    }

    const previousPage = () => {
        const page = paging.pageNum - 1;
        if (page <= 0) return;
        paging.pageNum = page;
        loadUserPermissions(paging);
    }

    return (
        <>
            <PageHeader
                title="User Permission List"
                description={`Manage and view all available User Permission from this page.`}
                className="mb-3"
            ></PageHeader>
            <Card>
                <Card.Body className="overflow-hidden p-lg-6">
                    <Row className="align-items-center justify-content-between">
                        <UserPermissionTable
                            data={userPermissions}
                            paging={paging}
                            total={total}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            gotoPage={gotoPage}
                        />
                    </Row>
                </Card.Body>
            </Card>
        </>)
}